<template>
  <div class="container-fluid">
    <div class="row">
        <div class="col-12 px-0">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">
                Print Label
              </h4>
              <div class="row">
                <div class="col-3">
                  <div class="card-header p-0 pb-2">
                    <h5 class="card-title text-primary">
                      Select Printer
                    </h5>
                  </div>
                  <div v-for="(printer, index) in printers" :key="index" no-body class="card border cursor-pointer" @click="setPrinterLabel(printer)">
                      <div class="card-body d-flex justify-content-between align-items-center">
                        <div>
                          <h4 class="mb-25 font-weight-bolder">
                            {{ printer.VendorName }}
                          </h4>
                          <span>{{ printer.Name }}</span>
                        </div>
                        <p-avatar :color="selectedPrinter && selectedPrinter.Name === printer.Name ? `light-success` : `light-warning`" size="45px">
                          <feather-icon
                            size="21"
                            icon="PrinterIcon"
                          />
                        </p-avatar>
                      </div>
                    </div>
                </div>
                <div class="col-9">
                  <h5 class="card-title text-primary">
                    Label Options
                  </h5>
                  <div class="card-deck">
                    <div class="card border">
                      <div class="row p-1">
                        <div class="form-group col-12">
                          <dx-util-select-box
                            id="select-paper-printers"
                            ref="selectBoxPaper"
                            v-model="selectedLabel"
                            :data-source="printerLabels"
                            display-expr="LabelName"
                            label="Paper Size"
                            label-mode="floating"
                            item-template="item"
                          >
                            <template #item="{ data }">
                              <printer-paper-template :item-data="data" />
                            </template>
                          </dx-util-select-box>
                        </div>
                        <div class="form-group col-12">
                          <dx-util-text-box
                            id="code-label-text"
                            v-model="printerParams.codeLabelText"
                            :show-clear-button="true"
                            mode="text"
                            label="Barcode Text"
                            label-mode="floating"
                            placeholder="X001T19QE1"
                          />
                        </div>
                        <div class="form-group col-12">
                          <dx-util-text-box
                            id="code-title-text"
                            v-model="printerParams.codeLabelTitle"
                            :show-clear-button="true"
                            mode="text"
                            label="Title Text"
                            label-mode="floating"
                          />
                        </div>
                        <div class="form-group col-6">
                          <dx-util-date-box
                            id="code-exp-date"
                            v-model="printerParams.codeLabelExp"
                            label="Expiration Date"
                            label-mode="floating"
                            type="date"
                          />
                        </div>
                        <div class="form-group col-6">
                          <dx-util-number-box
                            id="code-copies-count"
                            v-model="printerParams.codeLabelCopies"
                            :show-clear-button="true"
                            :min="1"
                            label="Copies Count"
                            label-mode="floating"
                          />
                        </div>
                        <div class="divider mb-1 col-10 mr-auto ml-auto">
                          <div class="divider-text">
                            Actions
                          </div>
                        </div>
                        <div class="form-group col-4">
                          <dx-util-button
                            type="normal"
                            text="Preview"
                            class="btn-block"
                            @click="onCodeBarcodePreview"
                          />
                        </div>
                        <div class="form-group col-4">
                          <dx-util-button
                            type="default"
                            text="Print"
                            class="btn-block"
                            @click="onCodeBarcodePrint"
                          />
                        </div>
                        <div class="form-group col-4">
                          <dx-util-button
                            type="default"
                            text="Download FNSKU Label"
                            class="btn-block"
                            @click="onClickDownloadFnskuLabel"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="card border">
                      <div class="d-flex justify-content-center card-body">
                        <div class="align-self-center">
                          <img v-if="codeLabelImage" :src="codeLabelImage">
                          <div v-else class="p-2 bg-secondary text-white text-center">
                            Label Preview - Code 128
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-control-regex */
import useDownloadLabel from '@/libs/printer/label-download'
import usePrinter from '@/libs/printer/print'
import { formatDate } from '@core/utils/filter'
import printerVarabiles, { labelTypes } from '@/libs/printer/printer-varaibles'
import { LabelParam } from '@/http/models/printer/entity/labelparam'
import PrinterPaperTemplate from './PrinterPaperTemplate.vue'

export default {
  components: {
    PrinterPaperTemplate,
  },
  setup() {
    const {
      printers,
      labels,
      pshBase64,
      truncateTitle,
      printManualLabel,
      getLabelDocument,
      getLabels,
    } = usePrinter()
    const {
      downloadFnskuLabel,
      downloadContentLabel,
    } = useDownloadLabel()
    return {
      printers,
      labels,
      pshBase64,
      truncateTitle,
      printManualLabel,
      getLabelDocument,
      getLabels,
      downloadFnskuLabel,
      downloadContentLabel,
    }
  },
  data() {
    return {
      selectedPrinter: null,
      printerLabels: [],
      selectedLabel: {},
      codeLabelImage: null,
      printerParams: {
        codeLabelText: '',
        codeLabelTitle: '',
        codeLabelImage: null,
        codeLabelCopies: 1,
        codeLabelExp: null,
      },
      jsData: '',
    }
  },
  computed: {
  },
  methods: {
    async setPrinterLabel(printerInfo) {
      await this.getLabels(printerInfo.VendorName)
      this.selectedPrinter = printerInfo
      this.printerLabels.length = 0
      this.labels.forEach(item => {
        if (printerInfo) {
          const label = {
            printer: printerInfo,
            Id: item.Id,
            LabelName: item.Name,
          }
          this.printerLabels.push(label)
        }
      })
    },
    onCodeBarcodePrint() {
      if (labelTypes.BOX_NAME.value === this.selectedLabel.LabelName) {
        const params = this.setBoxNameParams()
        this.printManualLabel(
          this.selectedPrinter.VendorName,
          this.selectedPrinter.Address,
          this.selectedLabel.Id,
          params,
          this.printerParams.codeLabelCopies,
        )
      } else {
        const params = this.setFnskuLabelParams()
        this.printManualLabel(
          this.selectedPrinter.VendorName,
          this.selectedPrinter.Address,
          this.selectedLabel.Id,
          params,
          this.printerParams.codeLabelCopies,
        )
      }
    },
    setFnskuLabelParams(data) {
      let expDate = ''
      if (this.printerParams.codeLabelExp) {
        expDate = formatDate(this.printerParams.codeLabelExp)
      }
      const params = []
      params.push(new LabelParam(printerVarabiles.BARCODE.value, this.printerParams.codeLabelText))
      params.push(new LabelParam(printerVarabiles.EXPRESSION_DATE.value, expDate))
      const titleStr = this.truncateTitle(this.printerParams.codeLabelTitle)
      const titleArr = titleStr.split(/(.{50})/).filter(p => p)
      if (titleArr.length > 1) {
        params.push(new LabelParam(printerVarabiles.TITLE_LINE1.value, titleArr[0]))
        params.push(new LabelParam(printerVarabiles.TITLE_LINE2.value, titleArr[1]))
      } else {
        params.push(new LabelParam(printerVarabiles.TITLE_LINE1.value, titleArr[0]))
        params.push(new LabelParam(printerVarabiles.TITLE_LINE2.value, ''))
      }

      return params
    },
    setBoxNameParams() {
      const params = []
      params.push(new LabelParam(printerVarabiles.BOX_NAME.value, this.printerParams.codeLabelText))
    },
    async onCodeBarcodePreview() {
      if (labelTypes.BOX_NAME.value === this.selectedLabel.LabelName) {
        const params = this.setBoxNameParams()
        const doc = await this.getLabelDocument(
          this.selectedPrinter.VendorName,
          this.selectedLabel.Id,
          params,
        )
        this.codeLabelImage = `data:image/png;base64,${doc}`
      } else {
        const params = this.setFnskuLabelParams()
        const doc = await this.getLabelDocument(
          this.selectedPrinter.VendorName,
          this.selectedLabel.Id,
          params,
        )
        this.codeLabelImage = `data:image/png;base64,${doc}`
      }
    },
    onClickDownloadFnskuLabel() {
      const fnsku = this.printerParams.codeLabelText
      const expDate = this.printerParams.codeLabelExp
      const title = this.printerParams.codeLabelTitle
      this.downloadFnskuLabel(fnsku, title, expDate)
    },
    onClickdownloadContentLabel() {
    },
  },
}
</script>

<style>

</style>
