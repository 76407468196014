<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-row px-1 py-half">
      <div class="mr-1 align-self-center">
        <p-icon name="bi-printer-fill" />
      </div>
      <h5 class="align-self-center m-0">
        {{ itemData.printer.Name }}
      </h5>
    </div>
    <div class="px-1 py-half">
      {{ itemData.LabelName }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    itemData: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
<style scoped>
.dx-dropdownlist-popup-wrapper .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item-content {
  padding: 0px 0px !important;
}
.dx-list-item-content::before {
    content: "_";
    color: transparent;
    display: none;
    width: 0;
    float: left;
}
</style>
