/**
 * This file contains functions for generating and downloading labels in PDF format.
 * It includes functions for creating and formatting barcode labels, as well as functions for creating content labels.
 * The labels can be downloaded individually or in batches.
 * The functions in this file utilize the jsPDF library for PDF generation and the jspdf-autotable library for table formatting.
 * @module label-download
 */
/* eslint-disable global-require */
/* eslint-disable new-cap */
import { jsPDF } from 'jspdf'
import autoTable from 'jspdf-autotable'
import 'jspdf-barcode'

const PDF417 = require('pdf417-generator')
const JsBarcode = require('jsbarcode')

export default function useDownloadLabel() {
  const MAX_TITLE_LENGTH = 100

  function createCode128Barcode(fnsku, widthPx = 192, heightPx = 60, displayValue = false) {
    const canvas = document.createElement('canvas')
    canvas.width = widthPx
    canvas.height = heightPx

    const fontSize = Math.max(10, heightPx / 6)
    const textMargin = Math.max(2, heightPx / 30)

    JsBarcode(canvas, fnsku, {
      format: 'CODE128',
      displayValue: displayValue,
      font: 'sans-serif',
      fontSize: fontSize,
      width: widthPx / 192,
      height: heightPx,
      margin: 0,
      textMargin: textMargin,
    })

    const image = new Image()
    image.src = canvas.toDataURL('image/png')
    return image
  }

  function createFnskuLabel(doc, fnsku, title, barcode, expireDate) {
    doc.addImage(barcode, 'PNG', 3, 2, 44.8, 8)
    doc.text(fnsku, doc.internal.pageSize.getWidth() / 2, 12, { align: 'center' })

    if (expireDate) {
      const formattedExpireDate = new Date(expireDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })
      doc.text(`Exp: ${formattedExpireDate}`, doc.internal.pageSize.getWidth() / 2, 14, { align: 'center' })
    }
    const titleY = expireDate ? 18 : 14
    const titleMaxWidth = doc.internal.pageSize.getWidth() - 10
    if (title) {
      if (title.length > MAX_TITLE_LENGTH) {
        const truncatedTitle = `${title.substring(0, MAX_TITLE_LENGTH)}...`
        const lines = doc.splitTextToSize(truncatedTitle, titleMaxWidth)
        doc.text(lines, doc.internal.pageSize.getWidth() / 2, titleY, { align: 'center' })
      } else {
        doc.text(title, doc.internal.pageSize.getWidth() / 2, titleY, { align: 'center', maxWidth: titleMaxWidth })
      }
    }
    return doc
  }

  function downloadFnskuLabel(fnsku, title, expireDate = null, count = 1) {
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: [50.8, 25.4],
      precision: 1,
      userUnit: 72,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    })
    doc.setFont('Helvetica', 'normal')
    doc.setFontSize(6)

    const barcode = createCode128Barcode(fnsku)

    for (let index = 0; index < count; index++) {
      createFnskuLabel(doc, fnsku, title, barcode, expireDate)
      if (index < count - 1) {
        doc.addPage()
      }
    }

    doc.save(`${fnsku}.pdf`)
  }

  function downloadFnskuLabelList(batchName, items) {
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: [50.8, 25.4],
      precision: 1,
      userUnit: 72,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    })
    doc.setFont('Helvetica', 'normal')
    doc.setFontSize(6)
    items.forEach((item, pageIndex) => {
      const barcode = createCode128Barcode(item.fnsku)
      for (let index = 0; index < item.count; index++) {
        createFnskuLabel(doc, item.fnsku, item.title, barcode, item.expireDate)
        if (index < item.count - 1) {
          doc.addPage()
        }
      }
      if (pageIndex < items.length - 1) {
        doc.addPage()
      }
    })

    doc.save(`${batchName}.pdf`)
  }

  function downloadFnskuLabelMultiple(batchName, labels) {
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: [215.9, 279.4], // 8.5 x 11 inches in mm (letter size)
      precision: 1,
      userUnit: 96,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    })
    doc.setFont('Helvetica', 'normal')
    const labelWidth = 2.625 * 25.4
    const labelHeight = 1 * 25.4

    const labelsPerRow = 3
    const labelsPerColumn = 10
    const labelsPerPage = labelsPerRow * labelsPerColumn
    let currentLabelIndex = 0

    for (let i = 0; i < labels.length; i++) {
      const label = labels[i]
      for (let j = 0; j < label.count; j++) {
        currentLabelIndex += 1

        if (currentLabelIndex % labelsPerPage === 1 && currentLabelIndex > 1) {
          doc.addPage()
        }
        const positionIndex = (currentLabelIndex - 1) % labelsPerPage
        const colIndex = positionIndex % labelsPerRow
        const rowIndex = Math.floor(positionIndex / labelsPerRow)

        let xMargin = 2
        if (colIndex > 0) {
          xMargin = 6 * colIndex + xMargin
        }
        const x = (colIndex * labelWidth) + xMargin
        let y = (rowIndex * labelHeight) + 10

        // Add barcode
        doc.setFontSize(6)
        const barcode = createCode128Barcode(label.fnsku)
        y += 5
        doc.addImage(barcode, 'PNG', x + 5, y, labelWidth - 10, 10)
        y += 10 + 2
        doc.setFontSize(6)
        doc.text(label.fnsku, x + labelWidth / 2, y, { align: 'center' })
        y += 2
        if (label.expireDate) {
          const formattedExpireDate = new Date(label.expireDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })
          doc.text(x + labelWidth / 2, y, `Exp: ${formattedExpireDate}`, { maxWidth: labelWidth, align: 'center' })
          y += 2
        }
        const titleMaxWidth = labelWidth - 10
        let truncatedTitle = ''
        if (label.title.length > MAX_TITLE_LENGTH) {
          truncatedTitle = `${label.title.substring(0, MAX_TITLE_LENGTH)}...`
        } else {
          truncatedTitle = label.title
        }
        const titleLines = doc.splitTextToSize(truncatedTitle, titleMaxWidth)
        if (titleLines.length > 3) {
          titleLines.splice(2)
        }
        doc.text(x + labelWidth / 2, y, titleLines, { maxWidth: titleMaxWidth, align: 'center' })
      }
    }

    doc.save(`${batchName}.pdf`)
  }

  function createTwoDBarcode(label) {
    const canvas = document.createElement('canvas')
    const image = new Image()
    PDF417.draw(label, canvas, 4, 1, 10)
    image.src = canvas.toDataURL('image/png', 0.6)
    return image
  }

  async function downloadContentLabel(params) {
    const {
      boxName,
      boxWeight,
      shipFromLine1,
      shipFromLine2,
      shipFromLine3,
      shipFromLine4,
      shipFromLine5,
      shipFromLine6,
      shipToLine1,
      shipToLine2,
      shipToLine3,
      shipToLine4,
      shipToLine5,
      shipToLine6,
      shippingPlanName,
      createdAt,
      batchAndBoxName,
      itemQty,
      skuQty,
      barcode,
    } = params

    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: [101.6, 152.4],
      precision: 1,
      userUnit: 96,
      margin: {
        top: 0, right: 0, bottom: 0, left: 0,
      },
    })

    const xCenter = doc.internal.pageSize.getWidth() / 2
    let positionY = 6

    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(12)
    doc.text('PLEASE LEAVE THIS LABEL UNCOVERED', xCenter, positionY, { align: 'center' })

    positionY += 6
    doc.setFontSize(12)
    doc.text('FBA', 5, positionY, { align: 'left' })

    doc.setFontSize(10)
    doc.text(`${boxName} - ${boxWeight}lb`, doc.internal.pageSize.getWidth() - 5, positionY, { align: 'right' })

    positionY += 2
    doc.line(5, positionY, doc.internal.pageSize.getWidth() - 5, positionY, 'FD')

    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(8)
    positionY += 4
    doc.text(shipFromLine1, 5, positionY, { align: 'left' })
    doc.setFont('Helvetica', 'normal');

    [shipFromLine2, shipFromLine3, shipFromLine4, shipFromLine5, shipFromLine6].forEach((line, index) => {
      if (line) doc.text(line, 5, positionY + 3 * (index + 1), { align: 'left' })
    })

    doc.setFont('Helvetica', 'bold')
    doc.text(shipToLine1, xCenter, positionY, { align: 'left' })
    doc.setFont('Helvetica', 'normal');

    [shipToLine2, shipToLine3, shipToLine4, shipToLine5, shipToLine6].forEach((line, index) => {
      if (line) doc.text(line, xCenter, positionY + 3 * (index + 1), { align: 'left' })
    })

    positionY += 18

    doc.setFillColor(0, 0, 0)
    doc.rect(5, positionY, doc.internal.pageSize.getWidth() - 10, 4, 'F')
    doc.setTextColor(255, 255, 255)
    doc.text(shippingPlanName, 6, positionY + 3, { align: 'left' })

    const shipmentCreationDateTime = new Date(createdAt).toLocaleString('en-US', {
      year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit',
    })
    const shipmentCreationText = `Created: ${shipmentCreationDateTime}`
    const shipmentCreationTextWidth = doc.getStringUnitWidth(shipmentCreationText) * (doc.internal.getFontSize() / doc.internal.scaleFactor)
    doc.text(shipmentCreationText, doc.internal.pageSize.getWidth() - 6 - shipmentCreationTextWidth, positionY + 3, { align: 'left' })

    positionY += 6

    const shipmentIdBarcodeWidthMm = doc.internal.pageSize.getWidth() / 1.8
    const shipmentIdBarcode = await createCode128Barcode(batchAndBoxName, shipmentIdBarcodeWidthMm * 96, 20 * 96)
    doc.addImage(shipmentIdBarcode, 'PNG', 5, positionY, shipmentIdBarcodeWidthMm, 20, '', 'FAST')

    doc.setTextColor(0, 0, 0)
    doc.text(batchAndBoxName, 5 + shipmentIdBarcodeWidthMm / 2, positionY + 20 + 4, { align: 'center' })

    const shipmentIdPdf417Barcode = await createTwoDBarcode(batchAndBoxName)
    doc.addImage(shipmentIdPdf417Barcode, 'PNG', doc.internal.pageSize.getWidth() - 19, 26, 40, 15, '', 'FAST', 270)

    positionY += 40
    doc.line(5, positionY, doc.internal.pageSize.getWidth() - 5, positionY, 'FD')

    autoTable(doc, {
      startY: positionY,
      theme: 'plain',
      styles: { fontSize: 10, fontStyle: 'bold', cellPadding: 1 },
      columnStyles: { 0: { halign: 'center', valign: 'middle' }, 1: { halign: 'center', valign: 'middle' } },
      body: [
        [{ content: 'BOX CONTENT 2D BARCODE', colSpan: 2, styles: { halign: 'center', valign: 'middle', fontSize: 12 } }],
        [`Item QTY: ${itemQty}`, `SKU QTY: ${skuQty}`],
      ],
    })

    const contentLabel = await createTwoDBarcode(barcode)
    doc.addImage(contentLabel, 'PNG', doc.internal.pageSize.getWidth() / 2 - 45, positionY + 15, 90, 22.5, '', 'FAST')

    const labelCreatedAt = new Date().toLocaleString('en-US', {
      year: 'numeric', month: '2-digit', day: '2-digit',
    })
    const footerY = doc.internal.pageSize.getHeight() - 8

    autoTable(doc, {
      startY: footerY,
      tableId: 'footer',
      tableWidth: doc.internal.pageSize.getWidth() - 20,
      margin: {
        top: 0, right: 5, bottom: 0, left: 5,
      },
      theme: 'plain',
      styles: {
        fontSize: 8, fontStyle: 'normal', cellPadding: 0, minCellHeight: 6,
      },
      columnStyles: { 0: { halign: 'left', valign: 'middle', fontStyle: 'bold' }, 1: { halign: 'center', valign: 'middle', fontStyle: 'bold' } },
      body: [
        [labelCreatedAt, 'prepshiphub.com', ''],
      ],
      didDrawCell: data => {
        if (data.section === 'body' && data.column.index === 2) {
          const img = new Image()
          img.src = require('@/assets/images/logo/psh-logo.png')
          doc.addImage(img, 'PNG', data.cell.x + 4, data.cell.y, 12, 6, '', 'FAST')
        }
      },
    })

    await doc.save(`${batchAndBoxName}.pdf`, { returnPromise: true })
  }

  async function downloadWalmartShippingLabel(params) {
    const {
      shipmentId,
      companyName,
      originLocation,
      returnLocation,
      isBox,
      numberOf,
      totalCount,
      packageContent,
    } = params

    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: [101.6, 152.4],
      precision: 1,
      userUnit: 96,
      margin: {
        top: 0, right: 0, bottom: 0, left: 0,
      },
    })
    let startX = 5
    const xCenter = doc.internal.pageSize.getWidth() / 2
    let positionY = 6

    // left side of the label line 1 - Return to
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(12)
    doc.text('RETURN TO:', startX, positionY, { align: 'left' })

    // left side of the label line 2 - Return to
    positionY += 6
    startX += 5
    doc.setFont('Helvetica', 'normal')
    doc.setFontSize(12)
    doc.text(companyName, startX, positionY, { align: 'left' })
    startX = 5 // reset startX

    // left side of the label line 3 - Return to
    positionY += 6
    startX += 5
    doc.setFont('Helvetica', 'normal')
    doc.setFontSize(10)
    const returnLocationAddressLine1 = `${returnLocation.line1}`
    doc.text(returnLocationAddressLine1, startX, positionY, { align: 'left' })
    startX = 5 // reset startX

    positionY += 6
    startX += 5
    doc.setFont('Helvetica', 'normal')
    doc.setFontSize(10)
    const returnLocationAddressLine2 = `${returnLocation.line2}`
    doc.text(returnLocationAddressLine2, startX, positionY, { align: 'left' })
    startX = 5 // reset startX

    // left side of the label line 4 - Return to
    positionY += 6
    startX += 5
    doc.setFont('Helvetica', 'normal')
    doc.setFontSize(10)
    const returnLocationAddressLine3 = `${returnLocation.city} ${returnLocation.state}, ${returnLocation.zipcode} ${returnLocation.country}`
    doc.text(returnLocationAddressLine3, startX, positionY, { align: 'left' })
    startX = 5 // reset startX

    // right side of the label line 1
    const rightSidePositionY = positionY / 2
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(12)
    const centerizeRightSide = xCenter + (xCenter / 2)
    doc.text('Walmart', centerizeRightSide, rightSidePositionY, { align: 'center' })

    // right side of the label line 2
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(12)
    doc.text('Fullfillment Service', centerizeRightSide, rightSidePositionY + 6, { align: 'center' })

    // horizontal line 1 separator
    positionY += 4
    doc.line(0, positionY, doc.internal.pageSize.getWidth(), positionY, 'FD')

    // left side of the label line 5 - Fill Out
    positionY += 6
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(12)
    doc.text('FILL OUT:', startX, positionY, { align: 'left' })

    // right side of the label line 3 - Shipment ID
    const rightSideX = xCenter + 5
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(12)
    doc.text('Shipment ID:', rightSideX, positionY, { align: 'left' })

    // left side of the label line 6 - Fill Out
    positionY += 6
    startX += 5
    const numberOfStr = String(numberOf)
    const totalCountStr = String(totalCount)

    const textParts = isBox ? ['BOX ', numberOfStr, ' OF ', totalCountStr] : ['PALLET ', numberOfStr, ' OF ', totalCountStr]

    // Set font and size for normal text
    doc.setFont('Helvetica', 'normal')
    doc.setFontSize(12)

    // Write each part with appropriate font style
    textParts.forEach(part => {
      if (part === numberOfStr || part === totalCountStr) {
        doc.setFont('Helvetica', 'bold')
      } else {
        doc.setFont('Helvetica', 'normal')
      }
      doc.text(part, startX, positionY, { align: 'left' })
      startX += doc.getTextWidth(part) // Move startX for next part
    })

    startX = 5 // reset startX

    // right side of the label line 4 - Shipment ID
    doc.setFont('Helvetica', 'normal')
    doc.setFontSize(12)
    doc.text(shipmentId, rightSideX + 5, positionY, { align: 'left' })

    // right side of the label line 5 - Wallmart Logo
    const wallmartLogo = require('@/assets/images/logo/walmart.png')
    const img = new Image()
    img.src = wallmartLogo
    const aspectRatio = 4
    const desiredWidth = 40 // Adjust the desired width as needed
    const scaledHeight = Number((desiredWidth / aspectRatio).toFixed(2))
    const xCenterRight = xCenter + (xCenter / 2)
    positionY += 2
    doc.addImage(img, 'PNG', xCenterRight - (desiredWidth / 2), positionY, desiredWidth, scaledHeight, '', 'FAST')

    positionY += scaledHeight + 2

    // horizontal line 2 separator
    doc.line(0, positionY, doc.internal.pageSize.getWidth(), positionY, 'FD')

    // vertical line 1 separator
    const startY = 0
    const endY = positionY
    doc.line(xCenter, startY, xCenter, endY, 'FD')

    positionY += 6

    // Left side of the label line 7 - Ship To
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(12)
    doc.text('SHIP TO:', startX, positionY, { align: 'left' })
    startX += 5

    // Left side of the label line 8 - Ship To
    positionY += 6
    doc.setFont('Helvetica', 'normal')
    doc.setFontSize(10)
    doc.text(originLocation.line1, startX, positionY, { align: 'left' })

    // Left side of the label line 9 - Ship To
    positionY += 6
    doc.setFont('Helvetica', 'normal')
    doc.setFontSize(10)
    doc.text(originLocation.line2, startX, positionY, { align: 'left' })

    // Left side of the label line 10 - Ship To
    positionY += 6
    doc.setFont('Helvetica', 'normal')
    doc.setFontSize(10)
    const shipToLine1 = `${originLocation.city} ${originLocation.state}, ${originLocation.zipcode} ${originLocation.country}`
    doc.text(shipToLine1, startX, positionY, { align: 'left' })

    // horizontal line 3 separator
    positionY += 4
    doc.line(0, positionY, doc.internal.pageSize.getWidth(), positionY, 'FD')

    startX = 5 // reset startX
    // Left side of the label line 12 - Shipment ID Barcode
    positionY += 6
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(12)
    doc.text('SHIPMENT ID BARCODE:', startX, positionY, { align: 'left' })

    // Left side of the label line 13 - Shipment ID Barcode
    positionY += 2

    const shipmentIdBarcodeWidthMm = doc.internal.pageSize.getWidth() / 1.2
    const shipmentIdBarcode = await createCode128Barcode(shipmentId, shipmentIdBarcodeWidthMm * 96, 24 * 96, true)
    const shipmentIdBarcodeX = (doc.internal.pageSize.getWidth() - shipmentIdBarcodeWidthMm) / 2
    doc.addImage(shipmentIdBarcode, 'PNG', shipmentIdBarcodeX, positionY, shipmentIdBarcodeWidthMm, 20, '', 'FAST')

    // horizontal line 4 separator
    positionY += 26
    doc.line(0, positionY, doc.internal.pageSize.getWidth(), positionY, 'FD')

    // Left side of the label line 14 - Type of Shipment
    positionY += 6
    doc.setFont('Helvetica', 'bold')
    doc.setFontSize(12)
    doc.text(packageContent, xCenter, positionY, { align: 'center' })

    // Footer
    const labelCreatedAt = new Date().toLocaleString('en-US', {
      year: 'numeric', month: '2-digit', day: '2-digit',
    })
    const footerY = doc.internal.pageSize.getHeight() - 8

    autoTable(doc, {
      startY: footerY,
      tableId: 'footer',
      tableWidth: doc.internal.pageSize.getWidth() - 20,
      margin: {
        top: 0, right: 5, bottom: 0, left: 5,
      },
      theme: 'plain',
      styles: {
        fontSize: 10, fontStyle: 'normal', cellPadding: 0, minCellHeight: 6,
      },
      columnStyles: { 0: { halign: 'left', valign: 'middle', fontStyle: 'bold' }, 1: { halign: 'center', valign: 'middle', fontStyle: 'bold' } },
      body: [
        [labelCreatedAt, 'prepshiphub.com', ''],
      ],
      didDrawCell: data => {
        if (data.section === 'body' && data.column.index === 2) {
          const imgPsh = new Image()
          imgPsh.src = require('@/assets/images/logo/psh-logo.png')
          doc.addImage(imgPsh, 'PNG', data.cell.x + 4, data.cell.y, 12, 6, '', 'FAST')
        }
      },
    })

    await doc.save(`${shipmentId}.pdf`, { returnPromise: true })
  }

  return {
    downloadFnskuLabel,
    downloadFnskuLabelList,
    downloadFnskuLabelMultiple,
    downloadContentLabel,
    downloadWalmartShippingLabel,
  }
}
